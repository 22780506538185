import { Component, OnInit, Input } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { Globals } from 'app/_models/globals';
import { SupportManageSellersGetSellersResponseModel } from './_models/supportManageSellersGetSellersResponseModel';

import { NotificationService } from 'app/_services/notification.service';
import { SupportGerenciarVendedoresService } from './gerenciar-vendedores.service';

declare const $: any;
declare const isEmpty: any;

@Component({ selector: 'app-gerenciar-vendedores-interno', templateUrl: './gerenciar-vendedores-interno.component.html', styleUrls: ['./gerenciar-vendedores-interno.component.scss'] })
export class SupportGerenciarVendedoresInternoComponent implements OnInit {
    @Input() holdingSelecionada: any;
    @Input() isFromSupport: boolean;

    _searchString: string;

    oficinas: any;
    oficinasFiltradas: any;
    vendedores: any;

    constructor(
        private notificationService: NotificationService,
        private supportGerenciarVendedoresService: SupportGerenciarVendedoresService,
        private globals: Globals,
        private ngxLoader: NgxUiLoaderService)
    { }

    ngOnInit(): void {
        this.searchTerm = '';
        this.oficinas = [];
        this.oficinasFiltradas = [];
        this.vendedores = [];
    }

    alterarVendedor($event: any, buyer_company_id: number) {
        let seller_company_id = parseInt($($event.target).val());
        let seller = this.vendedores.find(v => v.seller_company_id === seller_company_id);

        if (isEmpty(seller))
            return;

        this.ngxLoader.startLoader('loader-principal');

        this.supportGerenciarVendedoresService.changeRelationship(buyer_company_id, seller.seller_company_id, seller.holding_id).subscribe({
            next: () => {
                this.notificationService.showSuccessToastr(this.globals.translate('support.gerenciar-vendedores.mensagens.sucesso.corpo'));
                this.ngxLoader.stopLoader('loader-principal');
            },
            error: () => {
                this.ngxLoader.stopLoader('loader-principal');
            }
        });
    }

    exportarCSV() {
        if (!this.oficinas || this.oficinas.length === 0)
            return;

        const separador = ';';
        let csvString =
            this.globals.translate('support.gerenciar-vendedores.cabecalho-csv.empresa') + separador +
            this.globals.translate('support.gerenciar-vendedores.cabecalho-csv.cnpj') + separador +
            this.globals.translate('support.gerenciar-vendedores.cabecalho-csv.vendedor') + '\n';

        $.each(this.oficinas, function (i, item) {
            csvString +=
                (item.fantasy_name) + separador +
                (item.cnpj_formatado) + separador +
                (item.vendedor_selecionado.name) + '\n';
        });

        let a = $('<a/>', { style: 'display:none', href: 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString), download: 'oficinas-vendedores.csv' }).appendTo('body');
        a[0].click();
        a.remove();
    }

    filtrarHoldings() {
        if (isEmpty(this.oficinas))
            return this.oficinas;

        const term: string = this._searchString.toLowerCase();

        const result = this.oficinas.filter(x => x.fantasy_name.toLowerCase().indexOf(term) !== -1);
        return result;
    }

    listarEmpresasAssociadas(holding_id: number, is_billable: boolean) {
        this.searchTerm = '';

        if (holding_id === 0) {
            this.oficinas = [];
            return;
        }

        if (!isEmpty(this.holdingSelecionada))
            this.holdingSelecionada.is_billable = is_billable;

        this.ngxLoader.startLoader('loader-principal');

        this.supportGerenciarVendedoresService.getAllSellers(holding_id, is_billable).subscribe({
            next: (response: ApiResponseModel<SupportManageSellersGetSellersResponseModel>) => {
                if (response && response.success) {
                    let result = response.result.relacaoEmpresaVendedores;

                    this.oficinas = result.empresas;
                    this.oficinasFiltradas = this.oficinas;
                    this.vendedores = result.vendedores;

                    this.ngxLoader.stopLoader('loader-principal');
                }
            },
            error: () => {
                this.ngxLoader.stopLoader('loader-principal');
            }
        });
    }

    removerAssociacao(empresa) {
        const indice = this.oficinas.indexOf(empresa);
        const seller_company_id = empresa.vendedor_selecionado ? empresa.vendedor_selecionado.seller_company_id : empresa.vendedor_rede_indicada;
        const buyer_company_id = empresa.buyer_company_id;

        this.ngxLoader.startLoader('loader-principal');
        this.supportGerenciarVendedoresService.removeRelationship(seller_company_id, buyer_company_id).subscribe({
            next: () => {
                this.oficinas.splice(indice, 1);
                this.notificationService.showSuccessToastr(this.globals.translate('support.gerenciar-vendedores.mensagens.excluir-associacao-sucesso.corpo'));
                this.ngxLoader.stopLoader('loader-principal');
            },
            error: () => {
                this.notificationService.showErrorToastr(this.globals.translate('support.gerenciar-vendedores.mensagens.excluir-associacao-falha.corpo'));
                this.ngxLoader.stopLoader('loader-principal');
            }
        });
    }



    get searchTerm() {
        return this._searchString;
    }

    set searchTerm(value) {
        this._searchString = value;
        this.oficinasFiltradas = this.filtrarHoldings();
    }
}
