import { Injectable } from '@angular/core';
import { SettingsService } from 'app/_services/settings.service';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class SupportGerenciarVendedoresService {
    private _domain: string = '/support/manage-sellers';

    constructor(private config: SettingsService) { }

    public changeRelationship(buyer_company_id: number, seller_company_id: number, holding_id: number): Observable<any> {
        const body = { buyer_company_id, seller_company_id, holding_id };
        return this.config.executePatch({ url: this._domain, body });
    }

    public getAllSellers(holdingId: number, isBillable: boolean): Observable<any> {
        return this.config.executeGet({ url: `${this._domain}/holdings/${holdingId}?isBillable=${isBillable}` });
    }

    public removeRelationship(sellerCompanyId: number, buyerCompanyId: number): Observable<any> {
        return this.config.executeDelete({ url: `${this._domain}/${sellerCompanyId}/buyers/${buyerCompanyId}` });
    }
}
