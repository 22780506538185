<div class="gerenciar-vendedores">
    <div class="card card-default col-md-8 offset-md-2">
        <div class="col-md-10 offset-md-1">
            @if (oficinas?.length <= 0) {
                <div class="vazio">
                    {{'support.gerenciar-vendedores.mensagens.vazio' | translate}}
                </div>
            } @else {
                <section class="d-flex flex-wrap striped">
                    <div class="input-group col-md-12 search">
                        <span class="input-group-text"><i class="fa fa-search"></i></span>
                        <input type="text" maxlength="100" placeholder="{{ 'support.gerenciar-vendedores.placeholder' | translate}}" class="form-control" [(ngModel)]="searchTerm" />
                    </div>
                    <header>
                        <div class="linha">
                            <div class="col3"><span>{{'support.gerenciar-vendedores.cabecalho.empresa' | translate}}</span></div>
                            <div class="col2"><span>{{'support.gerenciar-vendedores.cabecalho.cnpj' | translate}}</span></div>
                            <div class="col2"><span>{{'support.gerenciar-vendedores.cabecalho.vendedor' | translate}}</span></div>
                            @if (isFromSupport) {
                                <div class="col1"><span></span></div>
                            }
                        </div>
                    </header>
                    <div class="corpo">
                        <ng-scrollbar>
                            @for (empresa of oficinasFiltradas; track empresa) {
                                <div class="linha" [ngClass]="{ 'is-blocked': empresa.is_blocked && isFromSupport }">
                                    <div class="col3"><input type="text" disabled class="form-control form-control-sm" value="{{empresa.fantasy_name}} ({{empresa.buyer_name}})" /></div>
                                    <div class="col2"><input type="text" disabled class="form-control form-control-sm" value="{{empresa.cnpj_formatado}}" /></div>
                                    <div class="col2">
                                        <select class="form-select form-select-sm" (change)="alterarVendedor($event, empresa.buyer_company_id)" [disabled]="empresa.is_blocked && isFromSupport ? 'disabled' : null">
                                            @for (vendedor of vendedores; track vendedor) {
                                                <option value="{{vendedor.seller_company_id}}" [selected]="empresa.vendedor_selecionado.seller_company_id == vendedor?.seller_company_id">{{vendedor.name}}</option>
                                            }
                                        </select>
                                    </div>
                                    @if (isFromSupport) {
                                        <div class="col1">
                                            @if (!holdingSelecionada.is_billable) {
                                                <div>
                                                    <i class="fa fa-remove" [ngClass]="empresa.convite_id > 0 ? 'convite_' + empresa.convite_id : ''" (click)="removerAssociacao(empresa)"></i>
                                                </div>
                                            }
                                            @if (empresa.user_company_invited == empresa.vendedor_selecionado.seller_company_id && empresa.vendedor_associado == empresa.vendedor_selecionado.seller_company_id) {
                                                <i class="fa fa-envelope" [ngClass]="empresa.convite_id > 0 ? 'convite_' + empresa.convite_id : ''"></i>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        </ng-scrollbar>
                    </div>
                </section>
            }
        </div>
    </div>
</div>
