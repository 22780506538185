import { InputRule } from 'prosemirror-inputrules';
const isMarkActive = (state, type) => {
  const {
    from,
    $from,
    to,
    empty
  } = state.selection;
  if (empty) {
    return Boolean(type.isInSet(state.storedMarks || $from.marks()));
  }
  return state.doc.rangeHasMark(from, to, type);
};
const findNodeType = (type, $from) => {
  for (let i = $from.depth; i > 0; i -= 1) {
    if ($from.node(i).type === type) {
      return $from.node(i).type;
    }
  }
  return null;
};
const isNodeActive = (state, type, attrs = {}) => {
  const {
    selection
  } = state;
  const {
    $from,
    to
  } = selection;
  const node = findNodeType(type, $from);
  if (!Object.entries(attrs).length || !node) {
    return Boolean(node);
  }
  return to <= $from.end() && $from.parent.hasMarkup(type, attrs);
};
const getSelectionMarks = state => {
  let marks = [];
  const {
    selection,
    storedMarks
  } = state;
  const {
    from,
    to,
    empty,
    $from
  } = selection;
  if (empty) {
    marks = storedMarks || $from.marks();
  } else {
    state.doc.nodesBetween(from, to, node => {
      marks = [...marks, ...node.marks];
    });
  }
  return marks;
};
const getSelectionNodes = state => {
  const nodes = [];
  const {
    selection: {
      from,
      to
    }
  } = state;
  state.doc.nodesBetween(from, to, node => {
    nodes.push(node);
  });
  return nodes;
};

// Ref: https://github.com/ProseMirror/prosemirror-commands/blob/master/src/commands.js
const markApplies = (doc, ranges, type) => {
  for (const range of ranges) {
    const {
      $from,
      $to
    } = range;
    let canApply = $from.depth === 0 ? doc.type.allowsMarkType(type) : false;
    doc.nodesBetween($from.pos, $to.pos, node => {
      if (canApply) {
        return false;
      }
      canApply = node.inlineContent && node.type.allowsMarkType(type);
      return true;
    });
    if (canApply) {
      return true;
    }
  }
  return false;
};
const markInputRule = (regexp, markType, attrs) => {
  return new InputRule(regexp, (state, match, start, end) => {
    const {
      tr
    } = state;
    const from = start;
    let to = end;
    if (match[2]) {
      const textStart = start + match[0].indexOf(match[2]);
      const textEnd = textStart + match[2].length;
      if (textEnd < end) {
        tr.delete(textEnd, end);
      }
      if (textStart > start) {
        tr.delete(start, textStart);
      }
      to = start + match[2].length;
    }
    return tr.addMark(from, to, markType.create(attrs));
  });
};
const canInsert = (state, nodeType) => {
  const {
    $from
  } = state.selection;
  for (let d = $from.depth; d >= 0; d -= 1) {
    const index = $from.index(d);
    if ($from.node(d).canReplaceWith(index, index, nodeType)) {
      return true;
    }
  }
  return false;
};

/**
 * Generated bundle index. Do not edit.
 */

export { canInsert, getSelectionMarks, getSelectionNodes, isMarkActive, isNodeActive, markApplies, markInputRule };
