import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { NgxLoadingModule } from 'ngx-loading';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { TooltipModule } from 'ng2-tooltip-directive';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(http: HttpClient) { return new TranslateHttpLoader(http); }

import { SupportGerenciarVendedoresInternoComponent } from './gerenciar-vendedores-interno.component';
import { NG_SCROLLBAR_OPTIONS, NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
    declarations: [SupportGerenciarVendedoresInternoComponent],
    imports: [
        CommonModule,
        FormsModule,

        NgxLoadingModule,
        NgxUiLoaderModule,
        NgScrollbarModule,
        TooltipModule,
        TranslateModule.forChild({ loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] } }),
    ],
    providers: [
        { provide: NG_SCROLLBAR_OPTIONS, useValue: { visibility: 'hover' } }
    ],
    exports: [SupportGerenciarVendedoresInternoComponent]
})
export class SupportGerenciarVendedoresModule { }
